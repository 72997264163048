import React, { useState } from "react"
import SoundContext from "../state/SoundContext"
import Header from "./Header"
import Footer from "./Footer"
import { Helmet } from "react-helmet"

function Layout(props) {
  const [isMuted, setIsMuted] = useState(false)

  return (
    <>
      <Helmet>
        {/* <meta
          property="og:image"
          content="https://amplitudo.it/showreel_cover.png"
        />
        <meta property="og:image:width" content="300" />
        <meta property="og:image:height" content="300" />
        <meta property="og:title" content="Amplitudo" />
        <meta
          property="og:description"
          content="We are a Sound Agency specialised in every aspect of sound production: from concept to creative direction, from sound design to music, from recording to mastering."
        />
        <meta property="og:url" content="https://amplitudo.it" />
        <meta property="og:type" content="website" /> */}

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://amplitudo.it/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Amplitudo" />
        <meta
          property="og:description"
          content="We are a Sound Agency specialised in every aspect of sound production: from concept to creative direction, from sound design to music, from recording to mastering."
        />
        <meta
          property="og:image"
          content="https://amplitudo.it/showreel_cover.png"
        />

        {/* <!-- Twitter Meta Tags -- /> */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="amplitudo.it" />
        <meta property="twitter:url" content="https://amplitudo.it/" />
        <meta name="twitter:title" content="Amplitudo" />
        <meta
          name="twitter:description"
          content="We are a Sound Agency specialised in every aspect of sound production: from concept to creative direction, from sound design to music, from recording to mastering."
        />
        <meta
          name="twitter:image"
          content="https://amplitudo.it/showreel_cover.png"
        />

        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;900&display=block"
          rel="stylesheet"
        ></link>
      </Helmet>
      <SoundContext.Provider value={[isMuted, setIsMuted]}>
        <div>
          <Header path={props.path} locale={props.pageContext.locale} />
          <div className="body-container">{props.children}</div>
          <Footer path={props.path} locale={props.pageContext.locale} />
        </div>
      </SoundContext.Provider>
    </>
  )
}

export default Layout
