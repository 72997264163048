import React from "react"
import { Link } from "gatsby"
import LocalisedLink from "./LocalisedLink"
import Socials from "../components/Socials"
import { Container } from "semantic-ui-react"
import { CSSTransition } from "react-transition-group"
import menuItems from "../constants/menuItems.json"
import styles from "./Menu.module.scss"
import contacts from "../constants/contacts.json"

import itMessages from "../i18n/it.json"
import enMessages from "../i18n/en.json"

const messages = {
  it: itMessages,
  en: enMessages,
}

const date = new Date()

function Menu({ isMenuOpened, path, locale, setIsMenuOpened }) {
  const delocalisedPath = path.replace("/it", "")

  function renderMenuItems() {
    return menuItems.map((item, i) => {
      return (
        <div className={styles.itemContainer} key={i}>
          <LocalisedLink
            className={`${styles.item} menu-item`}
            to={item.link}
            locale={locale}
            onClick={() => setIsMenuOpened(false)}
          >
            {item.name}
          </LocalisedLink>
        </div>
      )
    })
  }

  return (
    <>
      <CSSTransition
        in={isMenuOpened}
        timeout={500}
        classNames="menu"
        unmountOnExit
      >
        <div className={styles.menuContainer}>
          <div className={styles.fullHeightContainer}>
            <div className={styles.innerContainer}>
              <div className={styles.addressContainer}>
                <div className="menu-address">
                  <h5>{messages[locale].italy}</h5>
                  <p>{contacts.address1}</p>
                  <p>{contacts.address2}</p>
                </div>
              </div>
              <div className={styles.languagesContainer}>
                <Link
                  className={styles.language}
                  to={delocalisedPath}
                  onClick={() => setIsMenuOpened(false)}
                >
                  en
                </Link>
                <Link
                  className={styles.language}
                  to={`/it/${delocalisedPath}`}
                  onClick={() => setIsMenuOpened(false)}
                >
                  it
                </Link>
              </div>
              <div className={styles.mobileSocials}>
                <Socials />
              </div>
              <div className={styles.itemsContainer}>{renderMenuItems()}</div>
            </div>
          </div>
          <div className={styles.menuFooter}>
            <div className={`${styles.menuFooterInner} menu-footer`}>
              <div className={styles.menuFooterSocialsContainer}>
                <Socials inverted />
              </div>
              <span className={styles.footerEmail}>{contacts.email}</span>
              <span className={styles.footerName}>
                © {date.getFullYear()} amplitudo
              </span>
            </div>
          </div>
        </div>
      </CSSTransition>
    </>
  )
}

export default Menu
