import React from "react"
import { Link } from "gatsby"
import locales from "../constants/locales"

function LocalisedLink({ to, locale, ...props }) {
  const path = locales[locale].default ? to : `/${locale}${to}`

  return (
    <Link {...props} to={path}>
      {props.children}
    </Link>
  )
}

export default LocalisedLink
