import React from "react"
import styles from "./Footer.module.scss"
import { Container } from "semantic-ui-react"
import LocalisedLink from "./LocalisedLink"
import menuItems from "../constants/menuItems.json"
import Socials from "./Socials"
import contacts from "../constants/contacts.json"

const date = new Date()

function Footer({ path, locale }) {
  function renderFooterMenuLinks() {
    const parsePath = path.replace("it", "").replace(/\//g, "")

    return menuItems
      .filter(item1 => item1.link.replace(/\//g, "") !== parsePath)
      .map((item, i) => {
        return (
          <LocalisedLink
            key={i}
            className={styles.footerMenuLink}
            to={item.link}
            locale={locale}
          >
            <h5 className={styles.footerMenuLinkText}>{item.name}</h5>
          </LocalisedLink>
        )
      })
  }

  return (
    <div>
      <div className={styles.desktopOnly}>
        <div className={styles.footerMenuContainer}>
          {renderFooterMenuLinks()}
        </div>
        <div className={styles.footerContainer}>
          <div className={styles.footerSocialsContainer}>
            <Socials inverted />
          </div>
          <div className={styles.footerDetails}>
            <span>{contacts.email}</span>
          </div>
          <div className={styles.footerYear}>
            <a
              href="https://www.iubenda.com/privacy-policy/90592230"
              className={styles.privacyPolicy}
              title="Privacy Policy"
            >
              Privacy Policy
            </a>
            <span className={styles.dot}> • </span>
            <span>© {date.getFullYear()} amplitudo</span>
          </div>
        </div>
      </div>

      <div className={styles.mobileOnly}>
        <div className={styles.footerContainer}>
          <div className={styles.footerSocialsContainer}>
            <Socials inverted />
          </div>
          <div className={styles.footerMenuContainer}>
            {renderFooterMenuLinks()}
          </div>
          <div className={styles.footerDetails}>
            <span>{contacts.email}</span>
            <a
              href="https://www.iubenda.com/privacy-policy/90592230"
              className={styles.privacyPolicy}
              title="Privacy Policy"
            >
              Privacy Policy
            </a>
            <span>© {date.getFullYear()} amplitudo</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
