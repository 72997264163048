import React from "react"
import { Icon } from "semantic-ui-react"
import styles from "./Socials.module.scss"
import socials from "../constants/socials.json"

function Socials({ inverted = false }) {
  function renderSocialIcons() {
    return socials.map((social, i) => {
      return (
        <a
          key={i}
          className={styles.socialsLink}
          href={social.link}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Icon
            className={styles.socialIcon}
            name={social.icon}
            inverted={inverted}
          />
        </a>
      )
    })
  }

  return <div>{renderSocialIcons()}</div>
}

export default Socials
